import { useToast, UseToastOptions } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';

const useToasts = () => {
  const { t } = useTranslation('backoffice');
  const toast = useToast();

  const commonOptions: UseToastOptions = {
    duration: 4000,
    position: 'top',
    isClosable: true,
  };

  const errorToast = (toastProps?: UseToastOptions) =>
    toast({
      title: t('toast.error', 'Virhe'),
      description: t('toast.error_message', 'Tapahtui virhe, yritä uudelleen'),
      status: 'error',
      ...commonOptions,
      ...toastProps,
    });

  const successToast = (toastProps?: UseToastOptions) =>
    toast({
      title: t('toast.success', 'Valmis'),
      description: t('toast.success_message', 'Tallennus onnistui'),
      status: 'success',
      ...commonOptions,
      ...toastProps,
    });

  const infoToast = (toastProps?: UseToastOptions) =>
    toast({
      title: t('toast.notice', 'Huomio!'),
      status: 'info',
      ...commonOptions,
      ...toastProps,
    });

  return {
    errorToast,
    successToast,
    infoToast,
  };
};

export default useToasts;
