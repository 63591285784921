import { FC } from 'react';
import dynamic from 'next/dynamic';
import { ChakraProvider, Flex } from '@chakra-ui/react';
import HeaderNav from '@/components/frontend/HeaderNav';
import Footer from '@/components/frontend/Footer';
import mfmTheme from '@/theme/index';
import { LayoutProvider } from '@/lib/context';
import MaintenanceBreakBanner from '@/components/common/MaintenanceBreakBanner';
import SessionWrapper from '@/components/common/SessionWrapper';

const ChatbotPlaceholder = dynamic(
  () => import('@/components/frontend/ChatbotPlaceholder'),
  { ssr: false },
);
const ExitPreview = dynamic(() => import('@/components/frontend/ExitPreview'));
const LogOut = dynamic(() => import('@/components/frontend/Logout'));

export interface LayoutProps {
  isPreview?: boolean;
  isIdentifiedPerson?: boolean;
}

const PublicLayout: FC<LayoutProps> = ({
  children,
  isPreview = false,
  isIdentifiedPerson = false,
}) => {
  return (
    <LayoutProvider>
      <ChakraProvider theme={mfmTheme}>
        <MaintenanceBreakBanner />

        <Flex
          flexDir="column"
          minHeight="100vh"
          width="100%"
          className="front-office"
          backgroundColor="backgroundColor"
          sx={{
            '@media print': { display: 'block', minHeight: 0 },
          }}
        >
          <HeaderNav />
          <Flex
            direction="column"
            flex="1 1 auto"
            as="main"
            sx={{
              '@media print': { display: 'block', minHeight: 0 },
            }}
          >
            <SessionWrapper passthrough>{children}</SessionWrapper>
          </Flex>
          <Flex width="100%" as="footer" layerStyle="noPrint">
            <Footer />
          </Flex>
        </Flex>
        {isPreview && <ExitPreview />}
        {isIdentifiedPerson && <LogOut />}
        <ChatbotPlaceholder />
      </ChakraProvider>
    </LayoutProvider>
  );
};

export default PublicLayout;
