import {
  AzureAdSession,
  AzureAdUser,
  OpScaUser,
  Session,
  User,
} from 'next-auth';
import userHasRole from './userHasRole';

export function isBackOfficeUser(
  session?: Session | null,
): session is AzureAdSession {
  return userHasRole(session, ['realtor', 'manager', 'supervisor']);
}

export function isAzureAdUser(user?: User | null): user is AzureAdUser {
  return !!(user && 'adUserId' in user);
}

export function isOpScaUser(user?: User | null): user is OpScaUser {
  return !!(user && 'ssn' in user && user.ssn);
}

export function isValidUser(
  user?: User | null,
): user is AzureAdUser | OpScaUser {
  return isAzureAdUser(user) || isOpScaUser(user);
}
