import { FC } from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { Session } from 'next-auth';
import { SessionProvider, useSession } from 'next-auth/react';
import { ApolloProvider, NormalizedCacheObject } from '@apollo/client';
import { NextSeo, DefaultSeo, NextSeoProps } from 'next-seo';
import { NextRouter, useRouter } from 'next/router';
import {
  appWithTranslation,
  SSRConfig,
  UserConfig,
  useTranslation,
} from 'next-i18next';
import dynamic from 'next/dynamic';
import { OpenGraph } from 'next-seo/lib/types';
import { ReCaptchaProvider } from 'next-recaptcha-v3';
import '../styles/globals.css';
import nextI18NextConfig from '../next-i18next.config';
import { useApollo } from '@/lib/apolloClient';
import { AppProvider } from '@/lib/context';
import type { LayoutProps } from '@/components/frontend/PublicLayout';
import ErrorBoundary from '@/components/common/ErrorBoundary';
import { SiteNav, EntryTranslations, Post } from '@/types';
import getConfig, { Config, useConfig } from '@/config';
import userHasRole from '@/lib/utils/userHasRole';

const BackofficeLayout = dynamic(
  () => import('@/components/back-office/common/BackofficeLayout'),
);

const PublicLayout = dynamic(
  () => import('@/components/frontend/PublicLayout'),
);

const DefaultLayout: FC = ({ children }) => <>{children}</>;

interface EntryPageAppProps {
  [otherKey: string]: unknown;
  metadata: null | {
    [key: string]: string;
    url: string;
    canonical: string;
  };
  navigations: null | SiteNav;
  entryTranslations: null | EntryTranslations;
  isPreview: undefined | boolean;
  isAdminMode: undefined | boolean;
  data: null | Post;
}

interface PageProps extends SSRConfig {
  session: null | Session;
  initialApolloState: NormalizedCacheObject;
}

interface ExtendedAppProps extends AppProps {
  Component: AppProps['Component'] & { layout: FC<LayoutProps> };
  pageProps: PageProps & EntryPageAppProps;
}

const additionalLinkTags = (
  domain: 'back-office' | 'front-office',
): NextSeoProps['additionalLinkTags'] => {
  const icons = [
    {
      rel: 'apple-touch-icon',
      sizes: '57x57',
      href: `/favicons/${domain}/apple-icon-57x57.png`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '60x60',
      href: `/favicons/${domain}/apple-icon-60x60.png`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '72x72',
      href: `/favicons/${domain}/apple-icon-72x72.png`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '76x76',
      href: `/favicons/${domain}/apple-icon-76x76.png`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '114x114',
      href: `/favicons/${domain}/apple-icon-114x114.png`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '120x120',
      href: `/favicons/${domain}/apple-icon-120x120.png`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '144x144',
      href: `/favicons/${domain}/apple-icon-144x144.png`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '152x152',
      href: `/favicons/${domain}/apple-icon-152x152.png`,
    },
    {
      rel: 'apple-touch-icon',
      sizes: '180x180',
      href: `/favicons/${domain}/apple-icon-180x180.png`,
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '192x192',
      href: `/favicons/${domain}/android-icon-192x192.png`,
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '32x32',
      href: `/favicons/${domain}/favicon-32x32.png`,
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '96x96',
      href: `/favicons/${domain}/favicon-96x96.png`,
    },
    {
      rel: 'icon',
      type: 'image/png',
      sizes: '16x16',
      href: `/favicons/${domain}/favicon-16x16.png`,
    },
    { rel: 'manifest', href: `/favicons/${domain}/manifest.json` },
  ];

  const ASSET_PREFIX = process.env.NEXT_PUBLIC_ASSET_PREFIX;

  if (!ASSET_PREFIX || typeof ASSET_PREFIX !== 'string') {
    return icons;
  }

  /* Preload most used Inter fonts */
  const preloadInterFonts = ['Regular', 'SemiBold', 'Bold'].map((i) => ({
    rel: 'preload',
    href: `${ASSET_PREFIX}/fonts/inter-web/Inter-${i}.woff2?v=3.19`,
    as: 'font',
    type: 'font/woff2',
    crossOrigin: 'anonymous',
  }));

  const preconnect = {
    rel: 'preconnect',
    href: ASSET_PREFIX,
  };

  const dnsPrefetch = {
    rel: 'dns-prefect',
    href: ASSET_PREFIX,
  };

  return [...icons, ...preloadInterFonts, preconnect, dnsPrefetch];
};

const openGraphData = (
  router: NextRouter,
  config: Config,
  serviceName: string,
  metadata?: EntryPageAppProps['metadata'],
): OpenGraph => {
  return {
    images: [
      {
        url: config.OG_SHARE_IMAGE,
        width: 1260,
        height: 600,
        alt: serviceName,
      },
    ],
    site_name: serviceName,
    url: `${config.LOCALE_BASE_URL}${router.asPath}`,
    title: metadata?.title,
    description: metadata?.description,
    locale: router.locale,
  };
};

const getMetaData = (
  metadata: EntryPageAppProps['metadata'] | undefined,
  LOCALE_BASE_URL: string,
) => {
  const meta = {
    title: metadata?.title,
    description: metadata?.description,
  };
  if (!metadata?.canonical) {
    return meta;
  }
  // WP puts out only relative urls if all is well. Also trailing slash is to be removed as it's not in the url, so it wont match and causes robots to redirect loop
  return {
    ...meta,
    canonical: (metadata.canonical.startsWith('/')
      ? `${LOCALE_BASE_URL}${metadata.canonical}`
      : metadata.canonical
    ).replace(/\/$/, ''),
  };
};

const getLanguageAlternates = (
  entryTranslations: EntryTranslations | null,
): NextSeoProps['languageAlternates'] => {
  if (!entryTranslations) {
    return undefined;
  }

  return Object.entries(entryTranslations).map(([key, val]) => {
    const { LOCALE_BASE_URL } = getConfig({ locale: key });

    return {
      hrefLang: key.split('/')[0],
      href: `${LOCALE_BASE_URL}${val}`,
    };
  });
};

function MetsatilatApp({
  Component,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  pageProps: { session, initialApolloState, _nextI18Next, ...pageProps },
  isBackOfficeRoute,
  locale,
}: ExtendedAppProps & { isBackOfficeRoute: boolean; locale?: string }) {
  const { data: sessionData } = useSession();
  // Use given layout, or fallback to "none" in back-office and public in front-office
  const Layout =
    Component.layout || (isBackOfficeRoute ? DefaultLayout : PublicLayout);

  const { entryTranslations, navigations, ...componentProps } =
    pageProps as EntryPageAppProps;
  const isPreview = componentProps.isPreview;

  if (isBackOfficeRoute) {
    return (
      <>
        <NextSeo noindex nofollow />
        <BackofficeLayout>
          <Layout>
            <Component {...(pageProps as unknown as any)} />
          </Layout>
        </BackofficeLayout>
      </>
    );
  }

  return (
    <>
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-TTV9BMK');
          `,
        }}
      />
      <ReCaptchaProvider>
        <AppProvider
          locale={locale}
          template={componentProps?.data?.template || null}
          navigations={navigations || undefined}
          entryTranslations={entryTranslations}
        >
          <Layout
            isPreview={isPreview}
            isIdentifiedPerson={userHasRole(sessionData, ['identified_person'])}
          >
            <Component {...(componentProps as unknown as any)} />
          </Layout>
        </AppProvider>
      </ReCaptchaProvider>
    </>
  );
}

const AppWithSEOAndErrorBoundaries = (props: ExtendedAppProps) => {
  const {
    pageProps: { entryTranslations, session, metadata, initialApolloState },
  } = props;
  const router = useRouter();
  const config = useConfig();
  const apolloClient = useApollo(initialApolloState);
  const { t } = useTranslation('frontoffice');
  const { LOCALE_BASE_URL, SEO_ENABLED } = config;
  const serviceName = t('service_name', 'Metsätilat.fi');
  const isBackOfficeRoute = router.pathname.includes('/back-office');
  const domain = isBackOfficeRoute ? 'back-office' : 'front-office';

  return (
    <ErrorBoundary>
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <DefaultSeo
        titleTemplate={`%s - ${serviceName}`}
        defaultTitle={serviceName}
        dangerouslySetAllPagesToNoFollow={!SEO_ENABLED}
        dangerouslySetAllPagesToNoIndex={!SEO_ENABLED}
        dangerouslyDisableGooglebot={!SEO_ENABLED}
        additionalLinkTags={additionalLinkTags(domain)}
        openGraph={openGraphData(router, config, serviceName, metadata)}
        {...getMetaData(metadata, LOCALE_BASE_URL)}
        languageAlternates={getLanguageAlternates(entryTranslations)}
      />
      <SessionProvider session={session} refetchOnWindowFocus>
        <ApolloProvider client={apolloClient}>
          <MetsatilatApp
            {...props}
            isBackOfficeRoute={isBackOfficeRoute}
            locale={router.locale}
          />
        </ApolloProvider>
      </SessionProvider>
    </ErrorBoundary>
  );
};

export default appWithTranslation<ExtendedAppProps>(
  AppWithSEOAndErrorBoundaries,
  nextI18NextConfig as UserConfig,
);
