import * as Sentry from '@sentry/nextjs';
import { signOut } from 'next-auth/react';
import { useTranslation } from 'next-i18next';
import { useApollo } from '../apolloClient';
import useToasts from '../toasts';

const useSignOut = () => {
  const client = useApollo();
  const { errorToast } = useToasts();
  const { t } = useTranslation('frontoffice');

  const fullSignOut = (...signOutParams: Parameters<typeof signOut>) =>
    signOut(...signOutParams)
      .then(() => client.resetStore())
      .catch((e) => {
        console.error(e);
        Sentry.captureException(e);
        errorToast({
          description: t('signout_failed', 'Uloskirjautuminen epäonnistui'),
        });
      });

  return fullSignOut;
};

export default useSignOut;
